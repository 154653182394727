<template>
  <div :class="colSize" class="row q-mt-md q-py-sm">
    <div class="col-12 q-px-sm q-pt-md text-h5">Huidige Situatie</div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="currentProvider"
        label="Huidige Leverancier"
      />
    </div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        hide-bottom-space
        dense
        filled
        v-model="endDate"
        placeholder="DD-MM-YYYY"
        fill-mask
        mask="##-##-####"
        class="q-mb-none q-pb-none"
        hint="Einde contractdatum"
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :key="endDate"
                v-model="endDate"
                mask="DD-MM-YYYY"
                no-unset
              >
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="consumptionNormal"
        label="Normaal verbruik"
      />
    </div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="consumptionLow"
        label="Laag verbruik"
      />
    </div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="redelivery"
        label="Teruglevering"
      />
    </div>
    <div class="col-6 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="consumptionGas"
        label="Gas verbruik"
      />
    </div>
    <div class="col-12 q-my-sm q-px-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        v-model="note"
        autogrow
        maxlength="500"
        label="Opmerkingen"
        counter
        ><template v-slot:hint
      /></q-input>
    </div>
    <!-- <div class="col-6 q-my-sm q-px-sm">
      <q-btn
        no-caps
        @click="saveConsumption"
        color="primary"
        style="width: 100%"
        >Opslaan</q-btn
      >
    </div> -->
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $store = useStore();
const $q = useQuasar();

const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));

const currentProvider = computed({
  get() {
    return $store.getters.projectSpecific["huidigeLeverancier"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "huidigeLeverancier",
      value: newVal,
    });
  },
});

const consumptionNormal = computed({
  get() {
    return $store.getters.projectSpecific["normaalVerbruik"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "normaalVerbruik",
      value: newVal,
    });
  },
});
const consumptionLow = computed({
  get() {
    return $store.getters.projectSpecific["laagVerbruik"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "laagVerbruik",
      value: newVal,
    });
  },
});
const consumptionGas = computed({
  get() {
    return $store.getters.projectSpecific["gasVerbruik"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "gasVerbruik",
      value: newVal,
    });
  },
});
const redelivery = computed({
  get() {
    return $store.getters.projectSpecific["opwekkingZonnepanelen"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "opwekkingZonnepanelen",
      value: newVal,
    });
  },
});
const note = computed({
  get() {
    return $store.getters.projectSpecific["Opmerkingen"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Opmerkingen",
      value: newVal,
    });
  },
});

const endDate = computed({
  get() {
    return $store.getters.projectSpecific["eindeContractdatum"] ?? "";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "eindeContractdatum",
      value: newVal,
    });
  },
});
</script>
